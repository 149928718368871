import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomDigestButton from "../../../Components/CustomDigestButton";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import constants from "../../../Utils/constants";
import { PreferenceServices } from "../../../Services/PreferenceServices";
import Toast from "../../../Utils/Toast";
import {
  chain,
  cloneDeep,
  debounce,
  filter,
  find,
  map,
  flatMap,
  isArray,
} from "lodash";
import Collapsible from "react-collapsible";
import "./PreferenceCenterSettings.css";
import { BenchmarkServices } from "../../../Services/Benchmark";
import { MarketForecastServices } from "../../../Services/MarketForecast";
import { MarketLandscapeServices } from "../../../Services/MarketLandscape";
import { CompanyServices } from "../../../Services/Company";
import DoubleCheckIcon from "./PreferenceCenterIcon/DoubleCheckIcon.svg";
import MinusIcon from "./PreferenceCenterIcon/MinusIcon.svg";
import SingleCheckIcon from "./PreferenceCenterIcon/SingleCheckIcon.svg";
import UncheckIcon from "./PreferenceCenterIcon/UncheckIcon.svg";
import LockIcon from "./PreferenceCenterIcon/LockIcon.svg";
import { CustomerStudiesServices } from "../../../Services/CustomerStudies";
import { EcosystemServices } from "../../../Services/Ecosystem";
import StarIcon from "./PreferenceCenterIcon/StarIcon.svg";
import DisableStarIcon from "./PreferenceCenterIcon/DisableStarIcon.svg";
import { useHistory } from "react-router-dom";
import NoDataIcon from "./PreferenceCenterIcon/NoDataIcon.svg";
import Slugify from "../../../Utils/Slugify";

const PreferenceCenterSettings = ({
  segmentData,
  handleCollaps,
  allSegmentsData,
  selectedSegment,
  mainRef,
  othersItems,
  setOthersItems,
  isSegmentPart,
  handleOthersCollaps,
  platformNotificationData,
  platFromNotificationJson,
  setPlatformNotificationData,
}) => {
  const [frequencyType, setFrequencyType] = useState(constants.PC.IMMEDIATE);
  const [frequency, setFrequency] = useState(0); //1-daily, 7-weekly, 0-none
  const prevFrequency = useRef(null);
  const prevFrequencyType = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [prevFreqState, setPrevFreqState] = useState("");
  const [prevFreqTypeState, setPrevFreqTypeState] = useState("");
  const [allowNotification, setAllowNotification] = useState(true);
  const [selectedTab, setSelectedTab] = useState("company");
  const [segmentJson, setSegmentJson] = useState({});
  const [unsubscribeNotificationModal, setUnsubscribeNotificationModal] =
    useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [classicNotificationFlag, setClassicNotificationFlag] = useState(true);
  const history = useHistory();
  const [pauseNotificationModal, setpauseNotificationModal] = useState(false);
  const [reloadModel, setReloadModel] = useState(false);
  const [emailNotificationData, setEmailNotificationData] = useState({});
  const Legend = [
    { icon: DoubleCheckIcon, label: "Selected All" },
    { icon: UncheckIcon, label: "Deselected All" },
    { icon: MinusIcon, label: "Selected Few" },
    { icon: SingleCheckIcon, label: "Selected" },
    { icon: StarIcon, label: "Includes Earnings Response" },
  ];
  const tabField = {
    company: "is_all_company_checked",
    benchmark: "is_all_banchmark_checked",
    market_landscape: "is_all_marketlandscape_checked",
    market_forecast: "is_all_marketforecast_checked",
    customer_study: "is_all_customerstudies_checked",
    ecosystem: "is_all_ecosystems_checked",
    others: "is_all_others_checked",
    project: "is_all_projects_checked",
    special_report: "is_all_special_report_checked",
  };
  const selectionTab = [
    {
      label: "Company Research",
      value: "company",
      field: "is_all_company_checked",
      is_checked: false,
    },
    {
      label: "Benchmarks",
      value: "benchmark",
      field: "is_all_banchmark_checked",
      is_checked: false,
    },
    {
      label: "Market Landscapes",
      value: "market_landscape",
      field: "is_all_marketlandscape_checked",
      is_checked: false,
    },
    {
      label: "Market Forecasts",
      value: "market_forecast",
      field: "is_all_marketforecast_checked",
      is_checked: false,
    },
    {
      label: "Ecosystems",
      value: "ecosystem",
      field: "is_all_ecosystems_checked",
      is_checked: false,
    },
    {
      label: "Customer Studies",
      value: "customer_study",
      field: "is_all_customerstudies_checked",
      is_checked: false,
    },
    {
      label: "Other Reports",
      value: "others",
      is_checked: false,
      field: "is_all_others_checked",
    },
  ];
  // Function to check if a segment with the specified name exists in each object of the input array
  const checkSegmentIR = (array, segmentName) => {
    return map(array, (i) => {
      // Iterate over each object in the array
      const irObj = find(i.segments, (segment) => {
        return (
          segment.name === `${segmentName} Earnings Response` ||
          segment.name === `${segmentName} IR` ||
          segment.name === `${segmentName} ER`
        );
      });
      i.is_IR = !!irObj; // Set the is_IR property to true if irObj exists, otherwise false
      return i; // Return the modified object
    });
  };
  // Function to filter and process segment data based on type and item
  const filterSegmentdata = (array, type, item) => {
    let { id, name } = item; // Destructure id and name from item
    if (type === "companies") {
      // Check if type is 'companies'
      let temp = chain(array) // Start lodash chain
        .filter((s) => map(s.segments, "id").includes(id)) // Filter objects containing segments with matching id
        .map((i) => ({ is_checked: true, ...i })) // Add 'is_checked' property and spread object properties
        .value(); // Extract value from chain
      return checkSegmentIR(
        // Call checkSegmentIR function
        chain(temp) // Start another lodash chain with filtered data
          .map((i) => {
            // Iterate over each object in the filtered data
            let findSegment = find(i.segments, { id }); // Find the segment with matching id
            if (findSegment) {
              // If segment is found
              i.is_allowed = findSegment.allowed_access ? 1 : 0; // Set is_allowed property based on allowed_access
            }
            return i; // Return the modified object
          })
          .sortBy("name") // Sort filtered data by name
          .value(), // Extract value from chain
        name // Pass the name parameter to checkSegmentIR function
      );
    } else {
      // If type is not 'companies'
      return chain(array) // Start lodash chain
        .filter((s) => s.segment_id === id) // Filter objects with matching segment_id
        .map((i) => ({ is_checked: true, ...i })) // Add 'is_checked' property and spread object properties
        .sortBy("name") // Sort filtered data by name
        .value(); // Extract value from chain
    }
  };
  const updateSegmentDataWithPreferences = async () => {
    if (allSegmentsData.length) {
      const preferenceResponse =
        await PreferenceServices.getClientPreferences();
      const preferenceData = preferenceResponse.data.data;
      setFrequency(preferenceData.frequency);
      setFrequencyType(preferenceData.type);
      let request_for_classic_alters_off =
        preferenceData.request_for_classic_alters_off;
      setClassicNotificationFlag(request_for_classic_alters_off === 0);
      if (preferenceData?.preferences.length) {
        let notification = preferenceData?.preferences[0]?.published;
        setAllowNotification(notification !== 0);
      }
      let fitlerEmailNotificationData = preferenceData?.preferences.find(
        (i) => i.relation === "boards"
      );
      fitlerEmailNotificationData["entities_json"] = JSON.parse(
        fitlerEmailNotificationData?.entities_json
      );
      setEmailNotificationData(fitlerEmailNotificationData);
      await fetchAllReportSegmentData(preferenceData?.preferences);
    }
  };

  const handlePrefData = (prefData, segJson) => {
    let allTabKey = Object.keys(tabField);
    let prefFilter = filter(prefData, (i) => allTabKey.includes(i.relation));
    let tempSejJson = cloneDeep(segJson);
    for (let [key, value] of Object.entries(tempSejJson)) {
      for (let [subKey, subValue] of Object.entries(value)) {
        if (Array.isArray(subValue)) {
          // if (filter(subValue, (i) => i.is_allowed).length) {
          if (subKey === "others") {
            for (let index = 0; index < subValue.length; index++) {
              let el = subValue[index];
              let prefItem = find(prefFilter, (i) => i.relation === el.key);
              if (prefItem) {
                let entity_json = JSON.parse(prefItem?.entities_json);
                let get_entity_json = find(
                  entity_json,
                  (i) => parseInt(i.lob_id) === parseInt(key)
                );
                if (get_entity_json && get_entity_json.relation_ids !== null) {
                  tempSejJson[key][subKey][index].is_checked = false;
                  tempSejJson[key]["is_all_checked"] = false;
                  tempSejJson[key][tabField[subKey]] = false;
                }
              }
            }
          } else {
            let prefItem = find(prefFilter, (i) => i.relation === subKey);
            if (prefItem) {
              let entity_json = JSON.parse(prefItem?.entities_json);
              let get_entity_json = find(
                entity_json,
                (i) => parseInt(i.lob_id) === parseInt(key)
              );
              if (get_entity_json) {
                if (get_entity_json.relation_ids !== null) {
                  let filterAccesValue = subValue
                    .filter((i) => i.is_allowed)
                    .map((i) => i.id);
                  if (get_entity_json.relation_ids.length) {
                    tempSejJson[key]["is_all_checked"] = false;
                    tempSejJson[key][tabField[subKey]] = false;

                    for (let [k, i] of tempSejJson[key][subKey].entries()) {
                      if (get_entity_json.relation_ids.includes(i.id)) {
                        tempSejJson[key][subKey][k].is_checked = true;
                      } else {
                        tempSejJson[key][subKey][k].is_checked = false;
                      }
                    }
                  } else {
                    tempSejJson[key]["is_all_checked"] = false;
                    tempSejJson[key][tabField[subKey]] = false;
                    for (let [k, i] of tempSejJson[key][subKey].entries()) {
                      tempSejJson[key][subKey][k].is_checked = false;
                    }
                  }
                }
              } else {
                tempSejJson[key]["is_all_checked"] = false;
                tempSejJson[key][tabField[subKey]] = false;
                for (let index = 0; index < subValue.length; index++) {
                  tempSejJson[key][subKey][index].is_checked = false;
                }
              }
            }
          }
        }
      }
    }
    return tempSejJson;
  };

  const lobsToReplace = {
    "Public Sector": "Federal IT Services",
    "Professional Services": "IT Services",
    "Professional Services IR": "IT Services IR",
  };

  const replaceSegmentName = (array) => {
    return flatMap(array, (i) => {
      return {
        ...i,
        segments: map(i.segments, (el) => {
          return {
            ...el,
            name: lobsToReplace.hasOwnProperty(el.name)
              ? lobsToReplace[el.name]
              : el.name,
          };
        }),
      };
    });
  };

  // Function to fetch all report segment data asynchronously
  const fetchAllReportSegmentData = async (preferenceData) => {
    try {
      if (allSegmentsData.length) {
        // Check if allSegmentsData has elements
        // Fetch companies list asynchronously
        let companiesList = await CompanyServices.getTotalCompanies(
          "companies?1=1"
        );

        companiesList = replaceSegmentName(companiesList.data.data);

        // Fetch benchmark list asynchronously
        const benchmarkList = await BenchmarkServices.getBenchmarks(
          `/benchmarks`
        );
        // Fetch market forecast list asynchronously
        const marketForecastList =
          await MarketForecastServices.getMarketForecasts("/marketforecasts");
        // Fetch market landscape list asynchronously
        const marketLandscapeList =
          await MarketLandscapeServices.getMarketLandscapes(
            "/marketlandscapes"
          );
        // Fetch customer study list asynchronously
        const customerStudyList =
          await CustomerStudiesServices.getCustomerStudies(
            "/customer-study/list"
          );
        // Fetch ecosystem list asynchronously
        const ecosystemList = await EcosystemServices.getEcosystem(
          "/ecosystem/list"
        );
        let data = map(segmentData, (i) => ({
          [i.id]: {
            company: filterSegmentdata(companiesList, "companies", i),
            benchmark: filterSegmentdata(
              benchmarkList.data.data,
              "benchmark",
              i
            ),
            market_forecast: filterSegmentdata(
              marketForecastList.data.data,
              "market_forecast",
              i
            ),
            market_landscape: filterSegmentdata(
              marketLandscapeList.data.data,
              "market_landscape",
              i
            ),
            customer_study: filterSegmentdata(
              customerStudyList.data.data,
              "customer_study",
              i
            ),
            ecosystem: filterSegmentdata(
              ecosystemList.data.data,
              "ecosystem",
              i
            ),
            others: [
              {
                name: "Special Report",
                is_checked: true,
                is_allowed: 1,
                key: "special_report",
              },
              {
                name: "Project",
                is_checked: true,
                is_allowed: 1,
                key: "project",
              },
            ],
            is_all_checked: true,
            is_all_company_checked: true,
            is_all_banchmark_checked: true,
            is_all_marketforecast_checked: true,
            is_all_marketlandscape_checked: true,
            is_all_others_checked: true,
            is_all_customerstudies_checked: true,
            is_all_ecosystems_checked: true,
          },
        })).reduce((acc, obj) => ({ ...acc, ...obj }), {});

        if (preferenceData.length) {
          const prefData = handlePrefData(preferenceData, data);
          setSegmentJson(prefData); // Set the fetched data to segmentJson state
        } else {
          setSegmentJson(data);
        }
      }
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };
  const saveModalFrequency = () => {
    setFrequencyType(constants.PC.DIGEST);
    setOpenModal(!openModal);
    setReloadFlag(true);
    handlePreferenceCenterApi(
      segmentJson,
      constants.PC.DIGEST,
      allowNotification,
      frequency,
      true,
      emailNotificationData
    );
  };
  const closeModal = () => {
    if (prevFreqTypeState === constants.PC.IMMEDIATE) {
      setFrequencyType(constants.PC.IMMEDIATE);
    }
    setOpenModal(!openModal);
  };

  const setTheFrequency = (event) => {
    setPrevFreqState(prevFrequency.current);
    setFrequency(event);
  };
  const setTheFrequencyType = (payload) => {
    if (payload === constants.PC.DIGEST) {
      setOpenModal(!openModal);
    }
    setPrevFreqTypeState(prevFrequencyType.current);
    setFrequencyType(payload);
    if (
      prevFrequencyType.current !== payload &&
      payload !== constants.PC.DIGEST
    ) {
      setReloadFlag(true);
      handlePreferenceCenterApi(
        segmentJson,
        payload,
        allowNotification,
        frequency,
        true,
        emailNotificationData
      );
    }
  };

  const handlePreferenceCenterApi = useCallback(
    async (segObj, type, notification, freq, reload, enData) => {
      // Initialize an empty object to hold transformed data
      const transformedData = {};

      // Iterate over the segmentJson object using normal for loops
      for (const key in segObj) {
        if (segObj.hasOwnProperty(key)) {
          const segment = segObj[key];
          if (segment.is_all_checked) {
            for (const subKey in segment) {
              if (segment.hasOwnProperty(subKey)) {
                const subSegment = segment[subKey];
                // Check if the sub-segment is an array
                if (Array.isArray(subSegment)) {
                  // Filter items in the sub-segment that are allowed and checked

                  // Check if transformedData does not have the subKey and there are allowed and checked items
                  if (!transformedData[subKey]) {
                    // Initialize an empty array for the subKey in transformedData
                    transformedData[subKey] = [];
                  }
                  // Check if the subKey is 'others'
                  if (subKey === "others") {
                    // Iterate over items in the 'others' sub-segment
                    for (const item of subSegment) {
                      // Check if transformedData does not have the key of the item
                      if (!transformedData[item.key]) {
                        // Initialize an empty array for the key of the item in transformedData
                        transformedData[item.key] = [];
                      }
                      // Create a temporary object
                      const temp = {};
                      // Assign the key as a property of temp with value null if the item is checked, otherwise an empty array
                      temp[key] = null;
                      // Push temp into the array corresponding to the key of the item in transformedData
                      transformedData[item.key].push(temp);
                    }
                  } else {
                    // Create a temporary object
                    const temp = {};
                    // Assign the key as a property of temp with value null if segment.is_all_checked is true, otherwise checkedIds
                    temp[key] = null;
                    // Check if transformedData does not have the subKey
                    if (!transformedData[subKey]) {
                      // Initialize an empty array for the subKey in transformedData
                      transformedData[subKey] = [];
                    }
                    // Check if temp[key] is null or has length
                    // Push temp into the array corresponding to the subKey in transformedData
                    transformedData[subKey].push(temp);
                  }
                }
              }
            }
          } else {
            // Iterate over each sub-segment of the segment
            for (const subKey in segment) {
              if (segment.hasOwnProperty(subKey)) {
                const subSegment = segment[subKey];
                // Check if the sub-segment is an array
                if (Array.isArray(subSegment)) {
                  // Filter items in the sub-segment that are allowed and checked
                  const allowedCheckedItems = subSegment.filter(
                    (item) => item.is_allowed && item.is_checked
                  );
                  // Check if transformedData does not have the subKey and there are allowed and checked items
                  if (!transformedData[subKey] && allowedCheckedItems.length) {
                    // Initialize an empty array for the subKey in transformedData
                    transformedData[subKey] = [];
                  }
                  // Check if the subKey is 'others'
                  if (subKey === "others") {
                    // Iterate over items in the 'others' sub-segment
                    for (const item of subSegment) {
                      // Check if transformedData does not have the key of the item
                      if (!transformedData[item.key]) {
                        // Initialize an empty array for the key of the item in transformedData
                        transformedData[item.key] = [];
                      }
                      // Create a temporary object
                      const temp = {};
                      // Assign the key as a property of temp with value null if the item is checked, otherwise an empty array
                      temp[key] = item.is_checked ? null : [];
                      // Push temp into the array corresponding to the key of the item in transformedData
                      transformedData[item.key].push(temp);
                    }
                  } else {
                    // Map checked item ids in the sub-segment
                    const checkedIds = subSegment
                      .filter((item) => item.is_allowed && item.is_checked)
                      .map((item) => item.id);
                    // Check if there are checked ids and if segment.is_all_checked is true
                    if (checkedIds.length) {
                      // Create a temporary object
                      const temp = {};
                      // Assign the key as a property of temp with value null if segment.is_all_checked is true, otherwise checkedIds
                      temp[key] = segment.is_all_checked
                        ? null
                        : checkedIds.length ===
                          subSegment.filter((item) => item.is_allowed).length
                        ? null
                        : checkedIds;
                      // Check if transformedData does not have the subKey
                      if (!transformedData[subKey]) {
                        // Initialize an empty array for the subKey in transformedData
                        transformedData[subKey] = [];
                      }
                      // Check if temp[key] is null or has length
                      if (temp[key] === null || temp[key].length) {
                        // Push temp into the array corresponding to the subKey in transformedData
                        transformedData[subKey].push(temp);
                      }
                    } else {
                      if (segment[tabField[subKey]]) {
                        const temp = {};

                        temp[key] = null;
                        // Check if transformedData does not have the subKey
                        if (!transformedData[subKey]) {
                          // Initialize an empty array for the subKey in transformedData
                          transformedData[subKey] = [];
                        }
                        // Check if temp[key] is null or has length

                        // Push temp into the array corresponding to the subKey in transformedData
                        transformedData[subKey].push(temp);
                      } else {
                        const temp = {};

                        temp[key] = [];
                        // Check if transformedData does not have the subKey
                        if (!transformedData[subKey]) {
                          // Initialize an empty array for the subKey in transformedData
                          transformedData[subKey] = [];
                        }
                        // Check if temp[key] is null or has length

                        // Push temp into the array corresponding to the subKey in transformedData
                        transformedData[subKey].push(temp);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // Define a function to transform values
      const transformEntities = (value) => {
        // Map each item in the value array
        return value.map((item) => {
          // Get the key of the item
          const key = Object.keys(item)[0];
          // Return an object with lob_id as the key and relation_ids as the value
          return { lob_id: parseInt(key), relation_ids: item[key] };
        });
      };
      // Generate temporary preferences using lodash chain
      const tempPreferences = chain(transformedData)
        // Omit 'others' key from transformedData
        .omit("others")
        // Map values of transformedData
        .map((value, key) => ({
          relation: key,
          published: notification ? 1 : 0,
          updates: true,
          entities_json: transformEntities(value),
        }))
        // Get the final value from lodash chain
        .value();
      // Construct the final result object
      let getType = type || frequencyType;
      let frequencies = freq || frequency;
      const input = {
        type: getType,
        frequency: getType === constants.PC.IMMEDIATE ? 0 : frequencies,
        preferences: [...tempPreferences, enData],
      };
      // Log the result
      let r = reload !== undefined ? reload : reloadModel;
      if (r) {
        await PreferenceServices.setClientPreferences(input).then((res) => {
          setReloadFlag(false);
        });
      }
    },
    []
  );
  const handleReportCheck = (is_checked, reportName, field, id) => {
    // Deep copy of segmentJson
    setReloadFlag(true);
    const tempSegmentJson = cloneDeep(segmentJson);
    // Update field with is_checked
    tempSegmentJson[id][field] = is_checked;
    // Check if reportName is an array
    if (Array.isArray(tempSegmentJson[id][reportName])) {
      // Update is_checked property for each item in the array
      tempSegmentJson[id][reportName].forEach((item) => {
        item.is_checked = is_checked;
      });
    }
    if (!is_checked) {
      tempSegmentJson[id]["is_all_checked"] = is_checked;
    }
    tempSegmentJson[id]["is_all_checked"] = Object.entries(
      tempSegmentJson[id]
    ).every(([key, value]) =>
      typeof value === "boolean" ? value || key === "is_all_checked" : true
    );
    handleDebounce(
      tempSegmentJson,
      undefined,
      allowNotification,
      frequency,
      true,
      emailNotificationData
    );
    // Update the state
    setSegmentJson(tempSegmentJson);
  };
  const handleCollapsChecked = (id, is_checked) => {
    setReloadFlag(true);

    // Deep copy of segmentJson
    const tempSegmentJson = cloneDeep(segmentJson);
    // Iterate through keys of the nested object
    const keys = Object.keys(tempSegmentJson[id]);
    for (const key of keys) {
      if (typeof tempSegmentJson[id][key] === "boolean") {
        // Update boolean values directly
        tempSegmentJson[id][key] = is_checked;
      } else if (Array.isArray(tempSegmentJson[id][key])) {
        // Handle arrays
        const items = tempSegmentJson[id][key];
        for (const item of items) {
          item.is_checked = is_checked;
        }
      }
    }

    // Update the is_all_checked property
    tempSegmentJson[id].is_all_checked = is_checked;
    handleDebounce(
      tempSegmentJson,
      undefined,
      allowNotification,
      frequency,
      true,
      emailNotificationData
    );
    // Update the state
    setSegmentJson(tempSegmentJson);
  };

  const handleReportItemChecked = (is_checked, index, id, data) => {
    setReloadFlag(true);
    // Create a deep copy of segmentJson
    const temp = cloneDeep(segmentJson);
    // Retrieve the selected tab's items
    const tempItem = [...temp[id]?.[selectedTab]];
    // Update the specific item's "is_checked" property
    if (tempItem && tempItem[index]) {
      tempItem[index].is_checked = is_checked;
      if (!is_checked) {
        temp[id][tabField[selectedTab]] = is_checked;
        temp[id]["is_all_checked"] = is_checked;
      }
      if (
        data.filter((i) => i.is_allowed).length ===
        tempItem.filter((i) => i.is_checked && i.is_allowed).length
      ) {
        temp[id][tabField[selectedTab]] = true;
      }
      temp[id]["is_all_checked"] = Object.entries(temp[id]).every(
        ([key, value]) =>
          typeof value === "boolean" ? value || key === "is_all_checked" : true
      );
      // Update the state with the modified item
      temp[id][selectedTab] = tempItem;
      handleDebounce(
        temp,
        undefined,
        allowNotification,
        frequency,
        true,
        emailNotificationData
      );
      setSegmentJson(temp);
    }
  };

  const handleDebounce = useMemo(() => {
    return debounce(handlePreferenceCenterApi, 3000);
  }, [handlePreferenceCenterApi]);

  const handlePlatformNotificationPreferenceCenterApi = async (input) => {
    try {
      await PreferenceServices.updatePlatformNotificationPreferences({
        ...input,
        type: "immediate",
      });
    } catch (e) {
      console.error(
        "Error in handlePlatformNotificationPreferenceCenterApi",
        e
      );
    }
  };

  const handleDebounceForPlatformNotification = useMemo(() => {
    return debounce(handlePlatformNotificationPreferenceCenterApi, 3000);
  }, [handlePlatformNotificationPreferenceCenterApi]);

  const handleCollapIconItem = (
    is_all_checked,
    some_item_checked,
    id,
    name = null
  ) => {
    let iconSrc = "";
    let altText = "";
    let onClickHandler = () => {};

    if (is_all_checked) {
      iconSrc = DoubleCheckIcon;
      altText = "double-check";
      onClickHandler = () => {
        allowNotification && handleCollapsChecked(id, false);
      };
    } else if (some_item_checked) {
      iconSrc = MinusIcon;
      altText = "minus-check";
      onClickHandler = () => {
        allowNotification && handleCollapsChecked(id, true);
      };
    } else {
      iconSrc = UncheckIcon;
      altText = "uncheck-icon";
      onClickHandler = () => {
        allowNotification && handleCollapsChecked(id, true);
      };
    }
    return (
      <img
        src={iconSrc}
        alt={altText}
        onClick={(e) => {
          e.stopPropagation();
          onClickHandler();
        }}
      />
    );
  };

  const handleAllNotificationIcon = () => {
    const is_all_checked = Object.values(segmentJson).every(
      (e) => e.is_all_checked
    );
    let is_all_email_notification_check = true;
    if (emailNotificationData?.entities_json) {
      is_all_email_notification_check =
        Object.values(emailNotificationData?.entities_json?.[0]).filter(
          (i) => i
        ).length ===
        Object.keys(emailNotificationData?.entities_json?.[0]).length;
    }

    let iconSrc = "";
    let altText = "";
    let onClickHandler = () => {};
    if (!is_all_checked || !is_all_email_notification_check) {
      iconSrc = UncheckIcon;
      altText = "uncheck-icon";
      onClickHandler = () => {
        allowNotification && handleAllNotificationModel(true);
      };
    } else {
      iconSrc = DoubleCheckIcon;
      altText = "double-check";
      onClickHandler = () => {
        allowNotification && handleAllNotificationModel(false);
      };
    }
    return (
      <img
        src={iconSrc}
        alt={altText}
        onClick={(e) => {
          e.stopPropagation();
          onClickHandler();
        }}
      />
    );
  };

  const handleAllPlatformNotificationIcon = () => {
    const is_all_checked =
      Object.values(
        platformNotificationData?.preferences?.[0]?.entities_json[0]
      ).filter((i) => i).length ===
      Object.keys(platformNotificationData?.preferences?.[0]?.entities_json[0])
        .length;

    let iconSrc = "";
    let altText = "";
    let onClickHandler = () => {};
    if (!is_all_checked) {
      iconSrc = UncheckIcon;
      altText = "uncheck-icon";
      onClickHandler = () => {
        !platformNotificationData?.is_pause_notification &&
          handlePlatformAllNotificationModel(true);
      };
    } else {
      iconSrc = DoubleCheckIcon;
      altText = "double-check";
      onClickHandler = () => {
        !platformNotificationData?.is_pause_notification &&
          handlePlatformAllNotificationModel(false);
      };
    }
    return (
      <img
        src={iconSrc}
        alt={altText}
        onClick={(e) => {
          e.stopPropagation();
          onClickHandler();
        }}
      />
    );
  };

  const handleTabItem = (
    is_empty_data,
    is_all_checked,
    is_allowed_checked_array_length,
    is_not_accessible,
    tab,
    value,
    field,
    id,
    handleClick
  ) => {
    let iconSrc = "";
    let altText = "";
    let onClickHandler = () => {};
    if (is_empty_data) {
      if (is_all_checked || tab) {
        iconSrc = DoubleCheckIcon;
        altText = "double-check";
        onClickHandler = () => {
          allowNotification && handleReportCheck(false, value, field, id);
        };
      } else {
        iconSrc = UncheckIcon;
        altText = "uncheck-icon";
        onClickHandler = () => {
          allowNotification && handleReportCheck(true, value, field, id);
        };
      }
    } else if (is_allowed_checked_array_length === 0) {
      if (is_not_accessible) {
        iconSrc = LockIcon;
        altText = "lock-icon";
      } else {
        iconSrc = UncheckIcon;
        altText = "uncheck-icon";
        onClickHandler = () => {
          allowNotification && handleReportCheck(true, value, field, id);
        };
      }
    } else {
      if (tab) {
        iconSrc = DoubleCheckIcon;
        altText = "double-check";
        onClickHandler = () => {
          allowNotification && handleReportCheck(false, value, field, id);
        };
      } else {
        iconSrc = MinusIcon;
        altText = "minus-check";
        onClickHandler = () => {
          allowNotification && handleReportCheck(true, value, field, id);
        };
      }
    }
    return (
      <img
        src={iconSrc}
        alt={altText}
        onClick={(e) => {
          e.stopPropagation();
          if (selectedTab !== value) {
            handleClick();
          } else {
            onClickHandler();
          }
        }}
        style={{ opacity: selectedTab !== value ? "0.6" : "1" }}
      />
    );
  };
  const handleReportItem = (
    selectedTab,
    item_checked,
    index,
    id,
    tab,
    is_allowed,
    is_checked
  ) => {
    let content = null;
    if (selectedTab === "others") {
      content = (
        <label className="pref-toggle-switch">
          <input
            type="checkbox"
            onClick={(e) =>
              allowNotification &&
              handleReportItemChecked(!e.target.checked, index, id, tab)
            }
            checked={!item_checked}
          />
          <span className="pref-slider"></span>
        </label>
      );
    } else if (!is_allowed) {
      content = <img src={LockIcon} alt="lock-icon" />;
    } else if (is_checked) {
      content = (
        <img
          src={SingleCheckIcon}
          alt="single-check"
          onClick={(e) =>
            allowNotification && handleReportItemChecked(false, index, id, tab)
          }
        />
      );
    } else {
      content = (
        <img
          src={UncheckIcon}
          alt="uncheck-icon"
          onClick={(e) =>
            allowNotification && handleReportItemChecked(true, index, id, tab)
          }
        />
      );
    }
    return content;
  };

  const handlepauseNotificationModal = async (
    is_checked,
    platformNotificationflag
  ) => {
    setReloadFlag(true);
    if (platformNotificationflag) {
      let temp = { ...platformNotificationData };
      temp["is_pause_notification"] = is_checked ? 1 : 0;

      handleDebounceForPlatformNotification(temp);
      setPlatformNotificationData(temp);
    } else {
      if (!is_checked) {
        setAllowNotification(!is_checked);
        await handlePreferenceCenterApi(
          segmentJson,
          undefined,
          true,
          frequency,
          true,
          emailNotificationData
        );
      } else {
        setpauseNotificationModal(true);
      }
    }
  };

  const handleAllNotificationModel = async (is_checked) => {
    // Deep copy of segmentJson
    setReloadFlag(true);
    const tempSegmentJson = cloneDeep(segmentJson);
    const ids = Object.keys(tempSegmentJson);
    for (const id of ids) {
      // Iterate through keys of the nested object
      const keys = Object.keys(tempSegmentJson[id]);
      for (const key of keys) {
        if (typeof tempSegmentJson[id][key] === "boolean") {
          // Update boolean values directly
          tempSegmentJson[id][key] = is_checked;
        } else if (Array.isArray(tempSegmentJson[id][key])) {
          // Handle arrays
          const items = tempSegmentJson[id][key];

          for (const item of items) {
            item.is_checked = is_checked;
          }
        }
      }
      // Update the is_all_checked property
      tempSegmentJson[id].is_all_checked = is_checked;
    }

    const temp = cloneDeep(emailNotificationData);
    Object.keys(temp["entities_json"]?.[0]).map((key) => {
      temp["entities_json"][0][key] = is_checked ? 1 : 0;
    });

    handleDebounce(
      tempSegmentJson,
      undefined,
      allowNotification,
      frequency,
      true,
      temp
    );
    // Update the state
    setSegmentJson(tempSegmentJson);
    setEmailNotificationData(temp);
  };

  const handlePauseNotificationModel = async () => {
    setReloadFlag(true);
    setAllowNotification(!allowNotification);
    setpauseNotificationModal(false);
    await handlePreferenceCenterApi(
      segmentJson,
      undefined,
      false,
      frequency,
      true,
      emailNotificationData
    );
  };
  const handleClassicNotifiction = async () => {
    setClassicNotificationFlag(!classicNotificationFlag);
    setUnsubscribeNotificationModal(false);
    await PreferenceServices.offClassicNotification();
  };

  const handleKeyDown = async (event) => {
    if (event.key === "r" && (event.ctrlKey || event.metaKey)) {
      stopNavigationAndShowModal(event);
    }
  };
  const handleBeforeUnload = (event) => {
    stopNavigationAndShowModal(event);
  };
  const stopNavigationAndShowModal = (event) => {
    if (reloadFlag) {
      event.preventDefault();
      setReloadModel(true);
      handleDebounce(
        segmentJson,
        undefined,
        allowNotification,
        frequency,
        false,
        emailNotificationData
      );
    }
  };
  const cancelReloadModel = async () => {
    setReloadModel(false);
    await handlePreferenceCenterApi(
      segmentJson,
      undefined,
      true,
      frequency,
      true,
      emailNotificationData
    );
  };
  const handleRelaod = async () => {
    setReloadModel(false);
    window.location.reload();
  };

  useEffect(() => {
    // window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      // window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [reloadFlag, history]);

  useEffect(() => {
    prevFrequency.current = frequency;
    prevFrequencyType.current = frequencyType;
  }, [frequency, frequencyType]);

  useEffect(() => {
    updateSegmentDataWithPreferences();
  }, [allSegmentsData]);

  const handlePlatFromNotificationCheck = (key, value, index, mainIndex) => {
    if (!platformNotificationData?.["is_pause_notification"]) {
      let temp = { ...platformNotificationData };

      let tempPreferences = [...platformNotificationData?.preferences];
      tempPreferences[mainIndex]["entities_json"][0][key] = value;
      temp.preferences = tempPreferences;
      handleDebounceForPlatformNotification(temp);
      setPlatformNotificationData(temp);
    }
  };

  const handleEmailNotificationCheck = (key, value) => {
    let temp = { ...emailNotificationData };
    temp["entities_json"][0][key] = value;
    handleDebounce(
      segmentJson,
      undefined,
      allowNotification,
      frequency,
      true,
      temp
    );
    setEmailNotificationData(temp);
  };

  const handlePlatformAllNotificationModel = (value) => {
    let temp = { ...platformNotificationData };
    let tempPreferences = [...platformNotificationData?.preferences];
    tempPreferences.map((item) => {
      let data = {};
      Object.keys(item.entities_json[0]).forEach((key) => {
        data[key] = value;
      });
      item.entities_json[0] = data;
    });
    temp.preferences = tempPreferences;
    handleDebounceForPlatformNotification(temp);

    setPlatformNotificationData(temp);
  };

  const handlePlatFormNotificationCollapsChecked = (mainIndex, value, name) => {
    let temp = { ...platformNotificationData };
    let tempPreferences = [...platformNotificationData?.preferences];
    let tempEntitiesJson = tempPreferences[mainIndex]["entities_json"]?.[0];

    let data = {};
    Object.keys(tempEntitiesJson).forEach((key) => {
      data[key] = value;
    });
    tempPreferences[mainIndex]["entities_json"][0] = data;

    temp.preferences = tempPreferences;
    handleDebounceForPlatformNotification(temp);

    setPlatformNotificationData(temp);
  };

  const handleEmailNotificationCollapsCheck = (value) => {
    let tempEmail = { ...emailNotificationData };

    Object.keys(tempEmail["entities_json"]?.[0]).map((key) => {
      tempEmail["entities_json"][0][key] = value;
    });
    handleDebounce(
      segmentJson,
      undefined,
      allowNotification,
      frequency,
      true,
      tempEmail
    );

    setEmailNotificationData(tempEmail);
  };

  return (
    <div className="preference-content">
      <div className="pref-content-header">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // padding: "5px 0px 0px 0px",
            }}
          >
            {/* <div> */}
            <h3 className="pref-email-head">Email Notification Preferences</h3>
            {/* <p className="pref-email-para mb-0">
                Receive a compiled email notifications.
              </p> */}
            {/* </div> */}
            <div className="d-flex  align-item-center gap-10">
              <div className="d-flex  align-item-end justify-content-between gap-10">
                <div className="d-flex justify-content-end align-item-center gap-10">
                  <label className="pref-toggle-switch">
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        handlepauseNotificationModal(e.target.checked)
                      }
                      checked={!allowNotification}
                    />
                    <span className="pref-slider"></span>
                  </label>

                  <span className="font-weight-semibold">Notifications</span>
                </div>
                <div class="headerDivider"></div>

                <div className="d-flex justify-content-end align-item-center gap-10">
                  {handleAllNotificationIcon()}
                  <span className="font-weight-semibold">
                    All Notifications
                  </span>
                </div>
              </div>
              <CustomDigestButton
                frequencyType={frequencyType}
                setTheFrequencyType={setTheFrequencyType}
                openModal={openModal}
                closeModal={closeModal}
                frequency={frequency}
                setTheFrequency={setTheFrequency}
                saveModalFrequency={saveModalFrequency}
              />
            </div>
          </div>
          {!isSegmentPart && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 className="pref-email-head">
                Platform Notification Preferences
              </h3>

              <div className="d-flex  align-item-center gap-10">
                <div className="d-flex  align-item-end justify-content-between gap-10">
                  <div className="d-flex justify-content-end align-item-center gap-10">
                    <label className="pref-toggle-switch">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          handlepauseNotificationModal(e.target.checked, true)
                        }
                        checked={
                          platformNotificationData?.is_pause_notification
                        }
                      />
                      <span className="pref-slider"></span>
                    </label>
                    <span className="font-weight-semibold">Notifications</span>
                  </div>
                  <div class="headerDivider"></div>

                  <div className="d-flex justify-content-end align-item-center gap-10">
                    {handleAllPlatformNotificationIcon()}
                    <span className="font-weight-semibold">
                      All Notifications
                    </span>
                  </div>
                </div>
                <div
                  // onClick={() => setTheFrequencyType(constants.PC.IMMEDIATE)}
                  className="select-immediate"
                  style={{
                    padding: "5px 3.5px",
                    borderRadius: "2px",
                    border: "1px solid #e9ebed",
                    background: "var(--color-gray-10, #FFF)",
                  }}
                >
                  <span
                    style={{
                      background: "#2e6ad2",
                      color: "white",
                      padding: "4px 12px",
                      borderRadius: "2px",
                    }}
                  >
                    Immediate
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="pref-content-main-part">
        <div className="pref-legend-div">
          <div className="pref-legend-div-1">
            <span>Legend:</span>
            {Legend.map((i, index) => (
              <span key={index}>
                <img
                  src={i.icon}
                  alt={i.label}
                  style={{ width: "16px", height: "16px" }}
                />
                {i.label}
              </span>
            ))}
          </div>
          {classicNotificationFlag && (
            <div className="pref-legend-div-2">
              <span onClick={() => setUnsubscribeNotificationModal(true)}>
                Unsubscribe
              </span>
              to TBR Classic Notification
            </div>
          )}
        </div>

        {isSegmentPart ? (
          <div className="pref-collapser">
            {segmentData.map((item, index) => {
              let itemChecked = false;
              if (segmentJson?.[item?.id]) {
                let currentCheckedItem = Object.keys(segmentJson?.[item?.id])
                  .map((key, value) => {
                    if (
                      key.indexOf("is_all") !== -1 &&
                      key !== "is_all_checked" &&
                      segmentJson?.[item?.id][key]
                    ) {
                      return key;
                    }
                    if (isArray(segmentJson?.[item?.id][key])) {
                      if (
                        segmentJson?.[item?.id][key].filter(
                          (i) => i.is_allowed && i.is_checked
                        ).length
                      ) {
                        return true;
                      }
                    }
                  })
                  .filter((f) => f);

                if (currentCheckedItem.length > 0) {
                  itemChecked = true;
                }
              }

              return (
                <div
                  className="pref-collapser-item-new pref-collapse-list-new"
                  style={{
                    border: `1px solid ${
                      selectedSegment.id === item.id ? "#2E6AD2" : "#BCBCBC"
                    }`,
                    background:
                      selectedSegment.id === item.id ? "none" : "#F8F8F8CC",
                    borderRadius: "3px",
                    opacity: !allowNotification ? "0.6" : "1",
                  }}
                  key={index}
                >
                  <Collapsible
                    open={item.is_arrow_selected}
                    trigger={
                      <div
                        onClick={(e) => {
                          // setSelectedTab("company");
                          handleCollaps(index);
                          e.stopPropagation();
                        }}
                        className="preference-collapse-new"
                      >
                        <div className="preference-collapse-new-checkbox">
                          {handleCollapIconItem(
                            segmentJson[item.id]?.is_all_checked ?? false,
                            itemChecked ?? false,
                            item.id,
                            item.name
                          )}
                          <h2
                            className="pref-collapse-header-new"
                            style={{ fontWeight: "700", fontSize: "18px" }}
                          >
                            {item.name}
                          </h2>
                        </div>
                        <span
                          style={{
                            color: item.is_arrow_selected ? "#2E6AD2" : "#555",
                          }}
                          className="material-icons-outlined material-icon mr-2"
                        >
                          {item.is_arrow_selected
                            ? "expand_less"
                            : "expand_more"}
                        </span>
                      </div>
                    }
                  >
                    <div>
                      <div
                        className="segments-card-wrapper btn-items-card-wrapper bg-transparent m-0"
                        style={{ width: "100%", padding: "12px" }}
                      >
                        <div className="custom-card segments-card btn-items-card open-card">
                          <ul className="pref-collapser-item-tab-list">
                            {selectionTab.map((tab, index) => {
                              const segmentItem =
                                segmentJson?.[item.id]?.[tab.value];
                              const isAllowedCheckedArray = segmentItem?.filter(
                                (el) => el.is_allowed && el.is_checked
                              );
                              const isEmptyData =
                                !segmentItem || segmentItem.length === 0;
                              const isAllChecked =
                                segmentJson?.[item.id]?.["is_all_checked"];
                              const isNotAccessible =
                                segmentItem?.filter((el) => !el.is_allowed)
                                  .length === (segmentItem?.length ?? 0);
                              const handleClick = () =>
                                setSelectedTab(tab.value);
                              return (
                                <li
                                  key={index}
                                  id={`${Slugify(item.name)}_${item.id}`}
                                  ref={mainRef}
                                  className="pref-collapser-item-new-tab"
                                  style={{
                                    border:
                                      selectedTab === tab.value
                                        ? "1px solid #bcbcbc"
                                        : "none",
                                    background:
                                      selectedTab === tab.value
                                        ? "transparent"
                                        : "#F6F6F6",
                                  }}
                                  onClick={handleClick}
                                >
                                  {handleTabItem(
                                    isEmptyData,
                                    isAllChecked,
                                    isAllowedCheckedArray?.length,
                                    isNotAccessible,
                                    segmentJson?.[item.id]?.[tab.field],
                                    tab.value,
                                    tab.field,
                                    item.id,
                                    handleClick
                                  )}
                                  <span
                                    style={{
                                      color:
                                        selectedTab === tab.value
                                          ? "#555555"
                                          : "#989898",
                                    }}
                                  >
                                    {tab.label}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                          <div
                            className="pref-header-divider"
                            style={{
                              margin: "15px 0px",
                              width: "100%",
                            }}
                          ></div>
                          <div className="pref-item-tab-div">
                            {segmentJson?.[item.id]?.[selectedTab]?.length >
                            0 ? (
                              segmentJson[item.id][selectedTab].map(
                                (report, index) => {
                                  const isAllowed = report.is_allowed;
                                  const isChecked = report.is_checked;
                                  const reportName = report.name;
                                  const isIR = report.is_IR;
                                  const handleTooltip = !isAllowed
                                    ? {
                                        opacity: "0.5",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                      }
                                    : {
                                        opacity: "1",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                      };
                                  return (
                                    <div
                                      className="pref-item-tab-div-data"
                                      key={index}
                                    >
                                      {handleReportItem(
                                        selectedTab,
                                        isChecked,
                                        index,
                                        item.id,
                                        segmentJson[item.id][selectedTab],
                                        isAllowed,
                                        isChecked
                                      )}
                                      {!report.is_allowed ? (
                                        <>
                                          <span
                                            style={handleTooltip}
                                            // data-for={`tooltip-${Slugify(
                                            //   index
                                            // )}-no-access`}
                                            // data-tip={`You are currently unable to access this report. Please contact your admin if you require access.`}
                                            // data-iscapture='true'
                                          >
                                            {report.name}
                                          </span>
                                          {/* <CustomReactTooltip
                                          id={`tooltip-${Slugify(
                                            index
                                          )}-no-access`}
                                          multiline={true}
                                        /> */}
                                        </>
                                      ) : (
                                        <span style={handleTooltip}>
                                          {reportName}
                                        </span>
                                      )}
                                      {isIR && (
                                        <>
                                          <img
                                            src={
                                              report.is_allowed
                                                ? StarIcon
                                                : DisableStarIcon
                                            }
                                            alt="star-icon"
                                          />
                                        </>
                                      )}
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div className="no-data-div">
                                <img src={NoDataIcon} alt="no-data" />
                                <div className="text">
                                  <span>
                                    Report’s not available. Coming Soon!
                                  </span>
                                  <span>
                                    At the moment, reports for this LOB segment
                                    are not available.
                                    <br /> They will be displayed in this
                                    section once they become available.
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapsible>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="pref-collapser">
            {platformNotificationData?.preferences?.map((item, mainIndex) => {
              let itemCheckJson = item.entities_json;
              let allItemCheckedLength = Object.values(itemCheckJson[0]).filter(
                (i) => i
              ).length;
              let allEmailNotificationCheck =
                emailNotificationData.entities_json;
              let allEmailItemCheckedLength = Object.values(
                allEmailNotificationCheck?.[0]
              ).filter((i) => i).length;

              const handlePlatFormNotificationCollapIconItem = (
                is_all_checked,
                some_item_checked,
                id,
                name = null
              ) => {
                const notificationClickCheck =
                  name === "Email Notification"
                    ? allowNotification
                    : !platformNotificationData?.is_pause_notification;

                let iconSrc, altText, collapseCheckValue;

                // Determine icon source, alt text, and collapse check value based on checks
                if (is_all_checked) {
                  iconSrc = DoubleCheckIcon;
                  altText = "double-check";
                  collapseCheckValue = 0;
                } else if (some_item_checked) {
                  iconSrc = MinusIcon;
                  altText = "minus-check";
                  collapseCheckValue = 1;
                } else {
                  iconSrc = UncheckIcon;
                  altText = "uncheck-icon";
                  collapseCheckValue = 1;
                }

                // Define a reusable click handler function with `name === null` condition
                const onClickHandler = () => {
                  if (notificationClickCheck && name === "Email Notification") {
                    handleEmailNotificationCollapsCheck(collapseCheckValue);
                  } else if (name === null) {
                    handleEmailNotificationCollapsCheck(collapseCheckValue);
                    handlePlatFormNotificationCollapsChecked(
                      mainIndex,
                      collapseCheckValue,
                      null
                    );
                  } else if (notificationClickCheck) {
                    handlePlatFormNotificationCollapsChecked(
                      mainIndex,
                      collapseCheckValue,
                      name
                    );
                  }
                };

                // Return the image component with the optimized click handler
                return (
                  <img
                    src={iconSrc}
                    alt={altText}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickHandler();
                    }}
                  />
                );
              };

              return (
                <div
                  className="pref-collapser-item-new pref-collapse-list-new"
                  style={{
                    border: `1px solid ${
                      selectedSegment.id === item.id ? "#2E6AD2" : "#BCBCBC"
                    }`,
                    background:
                      selectedSegment.id === item.id ? "none" : "#F8F8F8CC",
                    borderRadius: "3px",
                    opacity: !allowNotification ? "0.6" : "1",
                  }}
                  key={mainIndex}
                >
                  <Collapsible
                    open={selectedSegment.id === item.id}
                    trigger={
                      <div
                        onClick={(e) => {
                          // setSelectedTab("company");
                          handleOthersCollaps(item);
                          e.stopPropagation();
                        }}
                        className="preference-collapse-new"
                      >
                        <div className="preference-collapse-new-checkbox">
                          {handlePlatFormNotificationCollapIconItem(
                            allItemCheckedLength ===
                              Object.values(itemCheckJson[0]).length &&
                              allEmailItemCheckedLength ===
                                Object.values(allEmailNotificationCheck[0])
                                  .length,
                            allItemCheckedLength || allEmailItemCheckedLength,
                            item.id,
                            item.name
                          )}
                          <h2
                            className="pref-collapse-header-new"
                            style={{
                              fontWeight: "700",
                              fontSize: "18px",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.relation}
                          </h2>
                        </div>
                        <div className="preference-collapse-new-checkbox">
                          {handlePlatFormNotificationCollapIconItem(
                            allEmailItemCheckedLength ===
                              Object.values(allEmailNotificationCheck[0])
                                .length,
                            allEmailItemCheckedLength,
                            item.id,
                            "Email Notification"
                          )}
                          <h2
                            className="pref-collapse-header-new"
                            style={{
                              fontWeight: "700",
                              fontSize: "18px",
                              textTransform: "capitalize",
                            }}
                          >
                            Email Notification
                          </h2>
                        </div>
                        <div className="preference-collapse-new-checkbox">
                          {handlePlatFormNotificationCollapIconItem(
                            allItemCheckedLength ===
                              Object.values(itemCheckJson[0]).length,
                            allItemCheckedLength,
                            item.id,
                            "Platform Notification"
                          )}
                          <h2
                            className="pref-collapse-header-new"
                            style={{
                              fontWeight: "700",
                              fontSize: "18px",
                              textTransform: "capitalize",
                            }}
                          >
                            Platform Notification
                          </h2>
                        </div>
                        <span
                          style={{
                            color:
                              selectedSegment.id === item.id
                                ? "#2E6AD2"
                                : "#555",
                          }}
                          className="material-icons-outlined material-icon mr-2"
                        >
                          {selectedSegment.id === item.id
                            ? "expand_less"
                            : "expand_more"}
                        </span>
                      </div>
                    }
                  >
                    <div>
                      <div
                        className="segments-card-wrapper btn-items-card-wrapper bg-transparent m-0"
                        style={{ width: "100%", padding: "8px 12px 12px 12px" }}
                      >
                        <div className="custom-card segments-card btn-items-card open-card">
                          <div
                            className="pref-header-divider"
                            style={{
                              margin: "0px 0px 10px 0px",
                              width: "100%",
                            }}
                          ></div>
                          <div className="d-flex">
                            <div
                              className="pref-item-tab-div"
                              style={{ flexDirection: "column", width: "100%" }}
                            >
                              {itemCheckJson?.length > 0
                                ? Object.entries(itemCheckJson[0]).map(
                                    ([key, value], index) => {
                                      let emailNotificationCheck =
                                        emailNotificationData?.entities_json[0][
                                          key
                                        ];

                                      return (
                                        <div
                                          className="pref-item-tab-div-data"
                                          key={index}
                                          style={{ width: "100%" }}
                                        >
                                          <span
                                            style={{
                                              opacity: "1",
                                              fontWeight: "600",
                                              fontSize: "12px",
                                              textTransform: "capitalize",
                                              width: "33%",
                                            }}
                                          >
                                            {platFromNotificationJson?.[key] ||
                                              ""}
                                            :
                                          </span>

                                          <div style={{ width: "35%" }}>
                                            {emailNotificationCheck ? (
                                              <img
                                                src={SingleCheckIcon}
                                                alt="single-check"
                                                onClick={() =>
                                                  handleEmailNotificationCheck(
                                                    key,
                                                    0
                                                  )
                                                }
                                              />
                                            ) : (
                                              <img
                                                src={UncheckIcon}
                                                alt="single-check"
                                                onClick={() =>
                                                  handleEmailNotificationCheck(
                                                    key,
                                                    1
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                          <div>
                                            {value ? (
                                              <img
                                                src={SingleCheckIcon}
                                                alt="single-check"
                                                onClick={() =>
                                                  handlePlatFromNotificationCheck(
                                                    key,
                                                    0,
                                                    index,

                                                    mainIndex
                                                  )
                                                }
                                              />
                                            ) : (
                                              <img
                                                src={UncheckIcon}
                                                alt="single-check"
                                                onClick={() =>
                                                  handlePlatFromNotificationCheck(
                                                    key,
                                                    1,
                                                    index,

                                                    mainIndex
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapsible>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Modal
        isOpen={unsubscribeNotificationModal}
        toggle={() => setUnsubscribeNotificationModal(false)}
        className={
          "modal-sm modal-w-header custom-modal primary-modal notification-modal"
        }
      >
        <div>
          <span
            onClick={() => setUnsubscribeNotificationModal(false)}
            style={{ color: "#555555", float: "right", cursor: "Pointer" }}
            className="material-icons"
          >
            close
          </span>
        </div>
        <div className="pref-modal-content">
          <h3>Unsubscribe TBR Classic Notifications</h3>
          <p>
            Are you sure you want to stop receiving notifications from TBR
            Classic Notifications?
          </p>
        </div>
        <div className="mt-2 d-flex justify-content-center">
          <ModalFooter>
            <Button
              className="modal-btn btn-outline-primary"
              onClick={() => setUnsubscribeNotificationModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleClassicNotifiction}
              color="primary"
              className="modal-btn"
              type="submit"
            >
              Yes
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <Modal
        isOpen={pauseNotificationModal}
        toggle={() => setpauseNotificationModal(false)}
        className={
          "modal-sm modal-w-header custom-modal primary-modal notification-modal"
        }
      >
        <div>
          <span
            onClick={() => setpauseNotificationModal(false)}
            style={{ color: "#555555", float: "right", cursor: "Pointer" }}
            className="material-icons"
          >
            close
          </span>
        </div>
        <div
          className="pref-modal-content"
          style={{
            alignItems: "center",
          }}
        >
          <h3>Pause Notifications</h3>
          <p className="font-weight-semibold">
            Are you sure you want to pause notifications from TBR Insight
            Center?
          </p>
          <p className="font-weight-normal">
            You will maintain access to all TBR content your organization
            subscribes to, but will not receive notifications when new content
            is published.
          </p>
        </div>
        <div
          className="mt-2 d-flex"
          style={{
            justifyContent: "center",
          }}
        >
          <ModalFooter>
            <Button
              className="modal-btn btn-outline-primary"
              onClick={() => setpauseNotificationModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handlePauseNotificationModel}
              color="primary"
              className="modal-btn"
              type="submit"
            >
              Yes
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <Modal
        isOpen={reloadModel}
        className={`modal-confirm modal-sm custom-modal`}
      >
        <ModalHeader>
          <div className="">
            <span
              onClick={cancelReloadModel}
              style={{ color: "#555555", float: "right", cursor: "Pointer" }}
              className="material-icons"
            >
              close
            </span>
          </div>
          <h2 className="text-center">You want to move</h2>
        </ModalHeader>
        <ModalBody>
          <h3>Changes you made may not be saved.</h3>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-outline-primary modal-btn"
            onClick={cancelReloadModel}
          >
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="modal-right-btn modal-btn"
            onClick={handleRelaod}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default PreferenceCenterSettings;
